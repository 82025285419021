.dashboardBody {
	min-height: 100vh;
	background-color: #efececa7;
	width: 100%;
}


.statCardGrid {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	gap: 10px;
	padding: 20px;
	margin-top: 20px;
}

.statCardGridDoctor {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	gap: 10px;
	padding: 20px;
	margin-top: 20px;
}

.statCard {
	background-color: white;
	margin-bottom: 20px;
	padding: 0;
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.statCard:hover {
	transform: scale(1.02);
}

.dashWidget {
	border-radius: 4px;
	padding: 20px;
	position: relative;
}

.dashWidgetBg1 {
	width: 65px;
	float: left;
	color: #fff;
	display: block;
	font-size: 50px;
	text-align: center;
	line-height: 65px;
	background: #009efb;
	border-radius: 50%;
	font-size: 40px;
	height: 65px;
}

.dashWidgetBg2 {
	width: 65px;
	float: left;
	color: #fff;
	display: block;
	font-size: 50px;
	text-align: center;
	line-height: 65px;
	background: #55ce63;
	border-radius: 50%;
	font-size: 40px;
	height: 65px;
}

.dashWidgetBg3 {
	width: 65px;
	float: left;
	color: #fff;
	display: block;
	font-size: 50px;
	text-align: center;
	line-height: 65px;
	background: #7a92a3;
	border-radius: 50%;
	font-size: 40px;
	height: 65px;
}

.dashWidgetBg4 {
	width: 65px;
	float: left;
	color: #fff;
	display: block;
	font-size: 50px;
	text-align: center;
	line-height: 65px;
	background: #ffbc35;
	border-radius: 50%;
	font-size: 40px;
	height: 65px;
}

.dashWidgetInfo {
	text-align: right;
}

.dashWidgetInfoH3 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.dashWidgetInfo span i {
	width: 16px;
	background: #fff;
	border-radius: 50%;
	color: #666666;
	font-size: 9px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	margin-left: 5px;
}

.widgetTitle1 {
	background: #009efb;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 13px;
}

.widgetTitle2 {
	background: #55ce63;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 13px;
}

.widgetTitle3 {
	background: #7a92a3;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 13px;
}

.widgetTitle4 {
	background: #ffbc35;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 13px;
}

.appointmentTableTd {
	min-width: 200px;
}

.card-title {
	color: #333;
	font-size: 16px;
	margin-bottom: 20px;
}

.card {
	margin-bottom: 30px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	border: 0;
}

.card-title {
	margin-bottom: 1.125rem;
}

.card .card-header {
	background-color: rgba(255, 255, 255, 0.1);
	border: 0 none;
	font-size: 16px;
	line-height: 30px;
	padding: 15px;
}

.card .card-header .card-title {
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
	padding: 5px 0;
}

#welcomeBanner{
	margin-top: 70px;
	background-color: #603f8b;
    background-repeat: no-repeat;
    background-size: cover;
	width: 100%;
	height:300px;
	padding: 50px;
	border-bottom-left-radius: 22px;
	border-bottom-right-radius: 22px;
}

.bannerText{
	color: black;
}

#corporateWelcome{
	margin-top: 70px;
	background-color: #ffd343;
    background-repeat: no-repeat;
    background-size: cover;
	width: 100%;
	height:300px;
	padding: 50px;
	border-bottom-left-radius: 22px;
	border-bottom-right-radius: 22px;
}

.horizontalLine {
	border-top: 1px solid white;
	width: 30%;
	padding-bottom: 30px;
}

@media only screen and (max-width: 1000px) {

	.statCardGrid {
		grid-template-columns: 50% 50%;
		padding: 0;
		padding-bottom: 20px;
	}

	.statCard {
		margin: 0;
	}
}

@media only screen and (max-width: 768px) {

	.statCardGrid {
		grid-template-columns: 100%;
	}

	.card-title {
		font-size: 1.12em;
	}

	#welcomeBanner{
		margin-top: 70px;
		
		width: 100%;
		height:320px;
		padding: 20px;
		border-bottom-left-radius: 22px;
		border-bottom-right-radius: 22px;
	}
}