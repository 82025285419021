.sidebar {
    background-color: #170B3B !important;
    background-color: #170B3B !important;
}

#sidebar {
    font-size: 1.3em !important;
}


