.stepIcon,
.stepNumber {
  font-weight: bold;
}

.stepInactive {
  color: #170B3B !important;
}

.stepActive {
  color: #603f8b !important;
}

.stepCompleted {
  color: #603f8b !important;
}
