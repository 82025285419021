.headerContainer {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    margin-bottom: 20px;
}

.headerButtons {
    display: flex;
    align-items: center;
}

#appointmentMain {
    min-height: 100vh;
    width: 100%;
    margin-top: 50px;
    padding-left: 50px;
}

.pageTitle {
    color: #170B3B;
    padding: 5px 0;
    font-weight: bold;

}

.apptSlotDialog{
    width: 100% !important;
}

#slotGrid {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 30px 5px 30px 0;
}

#calendarDiv {
    border: 0px !important;
    width: 100% !important;
    border-radius: 8px;
    vertical-align: middle !important;
}

#slotCreationDiv {
    padding-left: 70px;
}

#appointmentMain h4 {
    color: #170B3B;
    font-weight: bold;
}


.slotCard {
    padding: 5px 0;
    border: 1px solid #170B3B;
    margin: 10px;
    cursor: pointer;
    width: 120px;
    text-align: center;
}

.slotCard:hover {
    transform: scale(1.05);
}


@media screen and (max-width:1000px) {

    #slotGrid {
        grid-template-columns: 100%;
    }

    #calendarDiv {
        display: none;
    }

    #slotCreationDiv {
        padding-left: 30px;
    }
}