a {
  text-decoration: none;
}

a:hover{
  color: #72ECEA !important;
}

a:active{
  color: #72ECEA !important;
}

a:focus{
  color: #72ECEA !important;
}

a .selected {
  color: #72ECEA !important;
}

input, textarea{
  color: white !important;
}

#tabpanel-0 > div, #tabpanel-1 > div, #tabpanel-2 > div, #tabpanel-3 > div, #tabpanel-4 > div, #tabpanel-5 > div, #tabpanel-6 > div, #tabpanel-7 > div, #tabpanel-8 > div, #tabpanel-9 > div, #tabpanel-10 > div {
  background: #000 !important;
}

#root > div > div:nth-child(2) > div.MuiBox-root.css-8c6bqy > div.MuiBox-root.css-14ya0hk > div > div {
  visibility: hidden;
  display: none;
}

#root > div > div:nth-child(2) > div.MuiBox-root.css-8c6bqy > div.MuiBox-root.css-f2ar7l > div > div {
  display: none;
}

.swiper-pagination{
  visibility: hidden;
}

body {
  margin: 0;
  max-width: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiBox-root {
  padding-top: 2rem;
  background: #efececa7;
}

.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: green;
  font-weight: bold;
}

.masonry-grid {
  display: flex;
  margin-left: -15px; 
  width: auto;
}

.masonry-column {
  padding-left: 15px;
  background-clip: padding-box;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 100%;
}

.row {
  margin: 0 !important;
  padding: 0 !important;
}
